import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ResponseWithErrors} from "../model/response";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {take} from "rxjs/operators";
import {CvStructure} from "../model/cv-structure";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import { StatService } from '../model/stat.service';

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckComponent implements OnInit, AfterViewInit {
  stat$ = this.statService.statistics$;
  textSelected$ = new BehaviorSubject<string>(null);
  response$ = new BehaviorSubject<ResponseWithErrors[]>(null);

  cvLoading$ = new BehaviorSubject<boolean>(false);
  cvSaving$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<string>(null);
  review$ = new BehaviorSubject<boolean>(false);

  @ViewChild('editor', {static: false}) set editor(content: ElementRef) {
    this._editor = content;
  }
  private _editor;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private statService: StatService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params : ParamMap)=> {
      this.nextCV(params.get('id'));
    });
    this.route.queryParams.subscribe((qParams) => {
      this.review$.next(Boolean(JSON.parse(qParams['review'])));
    })
  }

  ngAfterViewInit() {
  }

  editorFocus(): void {
    this.textSelected$.next(null);
  }

  skipCV(): void {
    this.cvLoading$.next(true);
    this.http.post(`${environment.api}/cv-structure-skip/${this.response$.getValue()[0].documentId}`, {}, {withCredentials: true}).pipe(take(1)).subscribe(() => {
        this.cvLoading$.next(false);
        this.nextCV();
      },
      (error) => {
        this.cvLoading$.next(false);
      }
    );
  }

  nextCV(id?: string): void {
    const url = id ? `${environment.api}/show-cv/${id}` : `${environment.api}/page-annotation-random`;
    this.cvLoading$.next(true);
    this.http.get(url, {withCredentials: true}).pipe(take(1)).subscribe((val: ResponseWithErrors[]) => {
        if (!val[0].fullText) {
          this.router.navigate(['check-ocr', val[0].documentId]);
        } else if (!id) {
          this.router.navigate(['check', val[0].documentId]);
        }

        this.cvLoading$.next(false);
        this.response$.next(val);
        this.textSelected$.next(null);

        if (this._editor) {
          this._editor.reset();
          if (val[0].validJson) {
            this._editor.setJson(val[0].validJson);
          }
        }

        this.statService.refresh();
      },
      () => {
        this.cvLoading$.next(false);
        alert('Nie mogę załadować kolejnego CV - skontaktuj się z administratorem.');
      }
    );
  }

  checkForError($event: CvStructure): void {
    this.cvLoading$.next(true);
    this.http.post(`${environment.api}/check-cv-for-errors/${this.response$.getValue()[0].documentId}`, {validJson: $event}, {withCredentials: true}).pipe(take(1)).subscribe((val: ResponseWithErrors[]) => {
        this.cvLoading$.next(false);
        this.error$.next(null);
      },
      (error) => {
        this.cvLoading$.next(false);
        this.error$.next(error.error);
      }
    );
  }

  save($event: CvStructure): void {
    this.error$.next(null);
    this.cvSaving$.next(true);
    this.http.patch(`${environment.api}/cv-structure-pattern/${this.response$.getValue()[0].documentId}`, {validJson: $event}, {withCredentials: true}).subscribe(() => {
        this.cvSaving$.next(false);
        this.nextCV();
      },
      (e) =>  {
        this.cvSaving$.next(false);
        if (e.status == 400) {
          alert('Rozpoznanie zawiera błędy -  nie można znaleźć ropoznania w tekście.');
          this.error$.next(e.error.errors);
        } else {
          alert('Błąd przy zapisie danych, skopiuj obiekt do notatnika i skontaktuj sie z administratorem.');
        }
      }
    );
  }

  propertyChangedByShortcut($event: string): void {
    this._editor.addProperty({property: $event, value: this.textSelected$.getValue()});
  }

  ocrRedirect() {
    this.router.navigate(['check-ocr', this.response$.getValue()[0].documentId]);
  }

  saveReview($event: CvStructure) {
    this.error$.next(null);
    this.cvSaving$.next(true);
    this.http.patch(`${environment.api}/cv-structure-pattern-review/${this.response$.getValue()[0].documentId}`, {validJson: $event}, {withCredentials: true}).subscribe(() => {
        this.cvSaving$.next(false);
        this.router.navigate(['review']);
      },
      (e) =>  {
        this.cvSaving$.next(false);
        if (e.status == 400) {
          alert('Rozpoznanie zawiera błędy -  nie można znaleźć ropoznania w tekście.');
          this.error$.next(e.error.errors);
        } else {
          alert('Błąd przy zapisie danych, skopiuj obiekt do notatnika i skontaktuj sie z administratorem.');
        }
      }
    );
  }
}
