import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Stat } from '../../model/stat';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatsComponent {
  @Input() daily: Stat;
  @Input() weekly: Stat;
  @Input() monthly: Stat;
}
