import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {BehaviorSubject} from "rxjs";
import {NgForm} from '@angular/forms';

interface Document {
  documentId: string;
  by: string;
  createdAt: string;
}

@Component({
  selector: 'app-languages',
  templateUrl: './review.component.html'
})
export class ReviewComponent implements OnInit {
  documents$ = new BehaviorSubject<Document[]>([]);

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.http.get<Document[]>(`${environment.api}/review-list`, {withCredentials: true}).subscribe(
      (documents) => this.documents$.next(documents),
    )
  }
}
