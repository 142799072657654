import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ResponseWithContents} from "../../model/response";

@Component({
  selector: 'app-cv-content-dialog',
  template: `
    <dialog #dialog>
      <button (click)="close()">Close dialog</button>
      <div class="compare-text" *ngIf="!loading">
        <pre [innerHTML]="text"></pre>
        <app-page-editor [responses]="responses"></app-page-editor>
      </div>
      <app-loader *ngIf="loading"></app-loader>
      <button (click)="close()">Close dialog</button>
    </dialog>
  `,
  styles: [
    `pre {
      white-space: pre-wrap;
    }
    .compare-text {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvContentDialogComponent {
  @Input() loading: boolean;
  @Input() responses: ResponseWithContents;
  @Input() text: string;
  @Output() closedDialog = new EventEmitter<boolean>();

  @ViewChild('dialog', {static: true})
  private dialog;

  showDialog(): void {
    this.dialog.nativeElement.showModal();
  }

  close() {
    this.closedDialog.emit(true);
    this.dialog.nativeElement.close();
  }
}
