import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Bound, Word} from '../model/response';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CanvasComponent implements AfterViewInit, OnChanges {
  @ViewChild('canvas', {static: true}) canvas: ElementRef;
  cx: CanvasRenderingContext2D;
  drag: boolean;
  rect: any = {};
  image: any;
  @Output()
  selectText: EventEmitter<string> = new EventEmitter();
  @Input()
  words: Word[];
  @Input()
  paragraphs: Bound[];
  @Input()
  imageSrc: string;

  ngAfterViewInit() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');
    this.redrawImage();
  }

  mouseDown(e): void {
    this.rect.startX = (e.pageX / this.canvas.nativeElement.offsetWidth) * this.canvas.nativeElement.width;
    this.rect.startY = (e.pageY / this.canvas.nativeElement.offsetHeight) * this.canvas.nativeElement.height;
    this.rect.w = 0;
    this.rect.h = 0;
    this.drag = true;
    this.draw();
  }

  mouseMove(e): void {
    if (this.drag) {
      this.rect.w = ((e.pageX / this.canvas.nativeElement.offsetWidth) * this.canvas.nativeElement.width - this.rect.startX);
      this.rect.h = ((e.pageY / this.canvas.nativeElement.offsetHeight) * this.canvas.nativeElement.height - this.rect.startY);
      this.draw();
    }
  }

  draw() {
    this.cx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.cx.drawImage(this.image, 0, 0);

    // this.drawParagraphs();

    this.cx.strokeStyle = '#FF0000';
    this.cx.lineWidth = 3;
    this.cx.setLineDash([1]);
    this.cx.strokeRect(this.rect.startX, this.rect.startY, this.rect.w, this.rect.h);
  }

  private drawParagraphs() {
    this.paragraphs.forEach((p) => {
      this.cx.strokeStyle = '#FF0000';
      this.cx.setLineDash([1]);
      this.cx.strokeRect(
        p.v[0].x * this.image.width, p.v[0].y * this.image.height,
        (p.v[1].x - p.v[0].x) * this.image.width, (p.v[2].y - p.v[0].y) * this.image.height
      );
    });
  }

  mouseUp(e): void {
    this.drag = false;
    this.selectText.emit(this.getTextFromSelection());
  }

  private getTextFromSelection(): string {
    let actualString = '';

    this.words.forEach((s) => {
      const minX = Math.min(s.v[0].x, s.v[1].x, s.v[2].x, s.v[3].x);
      const maxX = Math.max(s.v[0].x, s.v[1].x, s.v[2].x, s.v[3].x);
      const minY = Math.min(s.v[0].y, s.v[1].y, s.v[2].y, s.v[3].y);
      const maxY = Math.max(s.v[0].y, s.v[1].y, s.v[2].y, s.v[3].y);

      const startX = this.rect.startX / this.cx.canvas.width;
      const startY = this.rect.startY / this.cx.canvas.height;
      const w = this.rect.w / this.cx.canvas.width;
      const h = this.rect.h / this.cx.canvas.height;

      if (minX >= startX && maxX <= startX + w &&
        minY >= startY && maxY <= startY + h
      ) {
        actualString += s.text;
      }
    });


    return actualString.replace(/\s+$/, '');
  }

  private redrawImage(): void {
    this.image = new Image();
    this.image.src = this.imageSrc;
    this.image.onload = () => {
      this.cx.canvas.height = this.image.height;
      this.cx.canvas.width = this.image.width;
      this.cx.drawImage(this.image, 0, 0);
      // this.drawParagraphs();
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.cx && this.imageSrc) {
      this.redrawImage();
    }
  }
}
