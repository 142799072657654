import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CanvasComponent } from './canvas/canvas.component';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { EditorComponent } from './editor/editor.component';
import { LoaderComponent } from './loader/loader.component';
import { CheckComponent } from './check/check.component';
import { RouterModule, Routes } from "@angular/router";
import {ShortcutComponent} from "./shortcut/shortcut.component";
import {PageEditorComponent} from "./page-editor/page-editor.component";
import {CheckOcrComponent} from "./check-ocr/check-ocr.component";
import {OcrContentComponent} from "./check-ocr/ocr-content/ocr-content.component";
import {CvContentDialogComponent} from "./check-ocr/cv-content-dialog/cv-content-dialog.component";
import {CvOcrFormComponent} from "./check-ocr/cv-ocr-form/cv-ocr-form.component";
import {LanguagesComponent} from "./languages/languages.component";
import {ReviewComponent} from "./review/review.component";
import { StatsComponent } from './check/stats/stats.component';

const routes: Routes = [
  {
    path: 'check',
    component: CheckComponent,
  },
  {
    path: 'check/:id',
    component: CheckComponent,
  },
  {
    path: 'check-ocr/:id',
    component: CheckOcrComponent,
  },
  {
    path: 'languages',
    component: LanguagesComponent,
  },
  {
    path: 'review',
    component: ReviewComponent,
  },
];

@NgModule({
  declarations: [
    AppComponent,
    ShortcutComponent,
    CanvasComponent,
    CanvasComponent,
    EditorComponent,
    EditorComponent,
    LoaderComponent,
    CheckComponent,
    PageEditorComponent,
    CheckOcrComponent,
    OcrContentComponent,
    CvContentDialogComponent,
    CvOcrFormComponent,
    LanguagesComponent,
    ReviewComponent,
    StatsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgJsonEditorModule,
    RouterModule.forRoot(routes),
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
