import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EveryStat } from './stat';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StatService {

  private refreshSubject = new BehaviorSubject<boolean>(false);

  statistics$ = this.refreshSubject.pipe(
    switchMap(() => this.httpClient.get<EveryStat>(`${environment.api}/stat`, {withCredentials: true}))
  );

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public refresh(): void
  {
    this.refreshSubject.next(true);
  }
}
