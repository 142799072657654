import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {BehaviorSubject} from "rxjs";
import {NgForm} from '@angular/forms';

interface Lang {
  content: string;
  documentId: string;
}

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html'
})
export class LanguagesComponent implements OnInit {
  langs$ = new BehaviorSubject<Lang[]>(null);

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.http.get<Lang[]>(`${environment.api}/language`, {withCredentials: true}).subscribe(
      (langs) => this.langs$.next(langs),
    )
  }

  onSubmit(f: NgForm): void {
    if (!f.valid) {
      return;
    }

    this.http.post(`${environment.api}/language`, f.value, {withCredentials: true}).subscribe(
      () => this.refresh()
    )
  }
}
