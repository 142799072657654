import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ResponseWithContents} from "../model/response";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {take} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-check-ocr',
  templateUrl: './check-ocr.component.html',
  styleUrls: ['./check-ocr.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckOcrComponent implements OnInit {
  textSelected$ = new BehaviorSubject<string>(null);
  response$ = new BehaviorSubject<ResponseWithContents[]>(null);

  cvLoading$ = new BehaviorSubject<boolean>(false);
  cvSaving$ = new BehaviorSubject<boolean>(false);
  cvId$ = new BehaviorSubject<string>(null);
  cvText$ = new BehaviorSubject<string>(null);

  @ViewChild('dialogComponent', {static: true})
  private dialog;

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.cvId$.next(this.activatedRoute.snapshot.paramMap.get('id'));
    this.loadCv();
  }

  loadCv(): void {
    this.cvLoading$.next(true);
    this.http.get(`${environment.api}/show-cv/${this.cvId$.getValue()}`, {withCredentials: true}).pipe(take(1)).subscribe((val: ResponseWithContents[]) => {
        this.cvLoading$.next(false);
        this.response$.next(val);
        this.textSelected$.next(null);
      },
      () => {
        this.cvLoading$.next(false);
        alert('Nie mogę załadować kolejnego CV - skontaktuj się z administratorem.');
      }
    );
  }

  showCV($event: string): void {
    this.cvText$.next($event);
    this.dialog.showDialog();
  }

  skipCV(): void {
    this.cvLoading$.next(true);
    this.http.post(`${environment.api}/cv-structure-skip/${this.response$.getValue()[0].documentId}`, {}, {withCredentials: true}).pipe(take(1)).subscribe(() => {
        this.cvLoading$.next(false);
        this.router.navigate(['check']);
      },
      (error) => {
        this.cvLoading$.next(false);
      }
    );
  }

  save($event): void {
    this.cvSaving$.next(true);
    this.http.post(`${environment.api}/ocr-quality/${this.response$.getValue()[0].documentId}`, {...$event}, {withCredentials: true}).subscribe(() => {
        this.cvSaving$.next(false);
        this.router.navigate(['check', this.response$.getValue()[0].documentId]);
      },
      () =>  {
        this.cvSaving$.next(false);
        alert('Błąd przy zapisie danych, skopiuj obiekt do notatnika i skontaktuj sie z administratorem.');
      }
    );
  }
}
