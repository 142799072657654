import {ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {complexShortcutCharactersArray, KeysProperties, numberCharacters, ShortcutCharacter} from "../model/chars";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-shortcut',
  templateUrl: './shortcut.component.html',
  styleUrls: ['./shortcut.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShortcutComponent {
  complexShortcutCharacters: ShortcutCharacter[] = complexShortcutCharactersArray;
  numbersShortCut = numberCharacters;
  actualPressedShortcut$ = new BehaviorSubject<ShortcutCharacter>(null);

  @Input()
  isTextSelected: boolean = false;

  @Output()
  propertyChanged: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('window:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    if (!this.isTextSelected) {
      return;
    }

    if (this.actualPressedShortcut$.getValue() && this.isDigit($event.key)) {
      this.addToArrayObject(
        this.numbersShortCut.findIndex(s => s === $event.key),
        this.actualPressedShortcut$.getValue().property,
        this.actualPressedShortcut$.getValue().secondProp
      );
      this.actualPressedShortcut$.next(null);
      $event.preventDefault();
      return;
    }

    if ($event.altKey && this.complexShortcutCharacters.map(c => c.char).includes($event.key)) {
      this.actualPressedShortcut$.next(this.complexShortcutCharacters.find((e) => e.char === $event.key));
      $event.preventDefault();
      return;
    }

    if ($event.key === 'z') {
      this.propertyChanged.emit('skills');
    }

    if ($event.key === 'x') {
      this.propertyChanged.emit('certificates');
    }

    if ($event.key === 'u') {
      this.propertyChanged.emit('personal.www');
    }

    Object.keys(KeysProperties).forEach((key) => {
      if (key === $event.key) {
        this.propertyChanged.emit(KeysProperties[key]);
        $event.preventDefault();
      }
    });

  }

  private isDigit(charCode): boolean {
    return this.numbersShortCut.includes(charCode);
  }

  addToArrayObject(i: number, property: string, moreProperty: string) {
    this.propertyChanged.emit(`${property}[${i}].${moreProperty}`);
  }
}
