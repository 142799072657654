export interface ShortcutCharacter {
  secondProp: string;
  char: string;
  property: string;
}
export const numberCharacters = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '=', 'p', '[', ']', '\\'];
export const complexShortcutCharactersArray: ShortcutCharacter[] = [
  {
    char: 'q',
    property: 'educations',
    secondProp: 'name'
  },
  {
    char: 'w',
    property: 'educations',
    secondProp: 'fieldOfStudy'
  },
  {
    char: 'e',
    property: 'educations',
    secondProp: 'degree'
  },
  {
    char: 'r',
    property: 'educations',
    secondProp: 'from'
  },
  {
    char: 't',
    property: 'educations',
    secondProp: 'to'
  },
  {
    char: 'y',
    property: 'educations',
    secondProp: 'description'
  },
  {
    char: 'u',
    property: 'educations',
    secondProp: 'department'
  },
  {
    char: 'i',
    property: 'educations',
    secondProp: 'specialization'
  },
  {
    char: 'a',
    property: 'experiences',
    secondProp: 'companyName'
  },
  {
    char: 's',
    property: 'experiences',
    secondProp: 'jobPosition'
  },
  {
    char: 'd',
    property: 'experiences',
    secondProp: 'from'
  },
  {
    char: 'f',
    property: 'experiences',
    secondProp: 'to'
  },
  {
    char: 'k',
    property: 'experiences',
    secondProp: 'to'
  },
  {
    char: 'g',
    property: 'experiences',
    secondProp: 'description'
  },
  {
    char: 'z',
    property: 'languages',
    secondProp: 'name'
  },
  {
    char: 'x',
    property: 'languages',
    secondProp: 'level'
  },
];
export enum KeysProperties {
  q = 'personal.firstName',
  w = 'personal.lastName',
  e = 'personal.email',
  r = 'personal.phone',
  t = 'personal.description',
  y = 'personal.birthdayDate',
  u = 'personal.www'
}
