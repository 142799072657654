import {CvStructure} from './cv-structure';

export interface Bound {
  v: {x: number, y: number}[];
}

export interface Word extends Bound {
  text: string;
}

export interface Response {
  documentId: string;
  images: string;
  result: {
    blocks: Bound[];
    paragraphs: Bound[];
    words: Word[];
    text: string;
  };
}

export interface ResponseWithErrors extends Response {
  errors: string[];
  fullText: string;
  validJson: CvStructure;
}

export enum ocrType {
  googleOCR = 'googleocr',
  pdfToText = 'pdftotext',
  tika = 'tika',
  tabula = 'tabula'
}

export interface ResponseWithContents extends Response {
  errors: string[];
  fullText: string;
  contents: {
    [ocrType.googleOCR]: string;
    [ocrType.pdfToText]: string;
    [ocrType.tika]: string;
    [ocrType.tabula]: string;
  }
}
