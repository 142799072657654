class Experience {
  companyName = '';
  jobPosition = '';
  from = '';
  to = '';
  description = [];
}

export class Education {
  name = '';
  degree = '';
  fieldOfStudy = '';
  from = '';
  to = '';
  description = [];
  department = '';
  specialization = '';
}

class PersonalInfo {
  firstName = '';
  lastName = '';
  email = '';
  phone = '';
  description = [];
  birthdayDate = '';
  www = [];
}

class Language {
  name = '';
  level = '';
}

export class CvStructure {
  personal: PersonalInfo = new PersonalInfo();
  experiences: Experience[] = [
    new Experience(),
    new Experience(),
    new Experience(),
    new Experience(),
    new Experience(),
    new Experience(),
    new Experience(),
    new Experience(),
    new Experience(),
    new Experience()
  ];
  educations: Education[] = [
    new Education(),
    new Education(),
    new Education(),
    new Education(),
    new Education(),
    new Education(),
    new Education(),
    new Education(),
    new Education(),
    new Education(),
    new Education()
  ];
  skills: string[] = [];
  certificates: string[] = [];
  languages: Language[] = [new Language(), new Language()];
}


