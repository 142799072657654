import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-ocr-content',
  template: `
      <a (click)="showContent()">Tekst {{order}}</a>
      <pre>
          {{ content }}
      </pre>
  `,
  styles: [
    `pre {
      white-space: pre-wrap;
    }`
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OcrContentComponent implements OnInit {
  @Input() content: string;
  @Input() order: number;
  @Output() contentClicked = new EventEmitter<string>();

  ngOnInit(): void {
  }

  showContent() {
    this.contentClicked.emit(this.content);
  }
}
