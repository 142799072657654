import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Response, ResponseWithContents} from "../model/response";
import {BehaviorSubject} from "rxjs";

enum ViewType {
  editor = 'editor',
  text = 'text'
}

@Component({
  selector: 'app-page-editor',
  templateUrl: './page-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageEditorComponent {
  @Input()
  responses: ResponseWithContents[];

  @Output()
  textSelected = new EventEmitter<string>();
  type$ = new BehaviorSubject<ViewType>(ViewType.text);

  types = ViewType;
  actualPage$ = new BehaviorSubject<number>(0);

  mouseUp(event): void {
    this.textSelected.emit(window.getSelection().toString());
  }
}
