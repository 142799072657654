import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ocrType} from "../../model/response";

@Component({
  selector: 'app-cv-ocr-form',
  template: `
    <form [formGroup]="form">
    <div class="first-row">
      <div class="row" *ngFor="let key of typeKeys">
        <input type="number" [formControlName]="key" [min]="1" [max]="typeKeys.length" [value]="key" [required]="true"/>
      </div>
    </div>
    <span class="error" *ngIf="form.hasError('duplicate')">Wartości muszą być różne.</span>
    <button (click)="clickSendForm()" [disabled]="!form.valid" class="send">Wyślij ocene</button>
    </form>
  `,
  styles: [
    `
      .first-row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 3em;
        padding: 0 1em;
      }
      input:invalid {
        box-shadow: 0 0 5px 1px red;
      }
      .error {
        box-shadow: 0 0 5px 1px red;
      }
      .send {
        display: block;
        padding: 4px;
        margin: 4px 0;
        text-align: center;
        background-color: coral;
        color:white;
        border: 2px solid transparent;
        border-radius: 0.5em;
        cursor: pointer;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvOcrFormComponent {
  @Output() sendForm = new EventEmitter();
  form: FormGroup;
  typeKeys = Object.keys(ocrType);

  constructor(private fb: FormBuilder) {
    const a  = this.typeKeys.reduce( (current, item) => {
      current[item] = [null, [Validators.required, Validators.min(1), Validators.max(this.typeKeys.length)]];
      return current;
    }, {});
    this.form = fb.group(a, {
      validators: this.differentValues
    });
  }

 differentValues(control: FormControl) {
    const v = Object.values(control.value);

     if ((new Set(v)).size !== v.length) {
       return {duplicate: 'Wartości się potwarzają.'}
     }

     return null;
  }

  clickSendForm() {
    if (!this.form.valid) {
      return;
    }

    this.sendForm.emit(this.form.value);
  }
}
