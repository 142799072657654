import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as _ from 'lodash';
import {CvStructure} from "../model/cv-structure";

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorComponent implements OnInit, OnChanges {
  @Output()
  editorWasFocused = new EventEmitter<boolean>();
  @Output()
  skipCv = new EventEmitter<boolean>();
  @Output()
  saveCV = new EventEmitter<CvStructure>();

  @Output()
  saveReview = new EventEmitter<CvStructure>();

  @Output()
  checkCV = new EventEmitter<CvStructure>();
  @ViewChild(JsonEditorComponent, { static: true }) editor: JsonEditorComponent;

  @Input()
  cvStructure = new CvStructure();

  @Input()
  admin = false;

  editorOptions: JsonEditorOptions;
  form: FormGroup;
  constructor(private fb: FormBuilder) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.mode = 'tree';
    this.editorOptions.expandAll = true;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      editor: [this.cvStructure]
    });
  }

  addProperty(value): void {
    if (value && this.form) {
      const o = this.form.get('editor').value;
      if (value.property === 'certificates' || value.property === 'skills' || value.property === 'personal.www' || value.property.includes('description')) {
        _.set(o, value.property, [..._.get(o, value.property, []), value.value]);
      } else {
        _.set(o, value.property, value.value);
      }

      this.form.get('editor').setValue(o);

      const path = _.toPath(value.property);

      const start = {path};
      const end = {path};
      this.editor.setSelection(start, end);
      const smallBox = this.editor.jsonEditorContainer.nativeElement.querySelector('.jsoneditor-tree');
      const element = this.editor.jsonEditorContainer.nativeElement.querySelector('.jsoneditor-selected');
      if (smallBox && element) {
        smallBox.scrollTop = element.getBoundingClientRect().top - 80;
      }
    }
  }

  public reset(): void {
    if (this.form) {
      this.form.get('editor').setValue(new CvStructure());
    }
  }

  public setJson(value): void {
    if (this.form) {
      this.form.get('editor').setValue(value);
    }
  }

  editorFocused() {
    this.editorWasFocused.emit(true);
  }

  skip() {
    this.skipCv.emit(true);
  }

  saveForm() {
    this.saveCV.emit(this.form.value.editor);
  }

  sendCheckCV() {
    this.checkCV.emit(this.form.value.editor);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.cvStructure.currentValue) {
      this.cvStructure = new CvStructure();
    }
  }

  saveReviewCV() {
    this.saveReview.emit(this.form.value.editor);
  }
}
